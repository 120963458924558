<template>
  <div class="page-header">
    <header class="lens-header-wrapper">
      <div class="header-wrapper">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
              <div class="lens-logo">
                <a href="" class="logo">
                  <img src="@/assets/title.png" alt="">
                </a>
              </div>

            </div>
            <div class="col-xl-10 col-lg-9 col-md-8 col-sm-6 col-6">
              <div class="main-menu-container">
                <div class="main-menu">
                  <ul>
                    <li><a href="/index" style="
                     font-weight:bold;               
				font-size:14px;
				font-family: Arial;">首页</a></li>
                    <li>
                      <a href="/info" style="font-size:14px;font-weight:bold;
				font-family: Arial;">资讯</a>
                    </li>
                    <!-- <li>
                      <a href="/houseDetail" target="_blank" style="font-weight:bold;
				font-size:14px;
				font-family: Arial;">关注房源</a>
                    </li>
                    <li>
                      <a href="" style="font-weight:bold;
				font-size:14px;
				font-family: Arial;">历史浏览</a>
                    </li>
                    <li>
                      <a href="" style="font-weight:bold;
				font-size:14px;
				font-family: Arial;">找房信息</a>
                    </li> -->
                  </ul>
                </div>
                <div class="lens-user-login-btn" @click="userLogin()">
                  <a class="user-btn">
                    <span class="lens-icon" style="">
                      <a-icon type="login" />登录
                    </span>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <a-modal :width="300" :footer="null" :visible="visible" :closable="false" @cancel="closeMoudle" :maskClosable="true"
      :zIndex="999">
      <div class="login-container">
        <div class="login-title">微信登录</div>
        <div class="qr-description-top">微信扫码关注小程序</div>
        <img src="@/assets/efind.png" alt="" />
        <div class="qr-description-bottom">可在小程序上完成登录</div>
      </div>
    </a-modal>
  </div>
</template>

<script>

export default {
  name: 'PageHeader',
  data() {
    return {
      visible: false
    }
  },
  methods: {
    userLogin() {
      this.visible = true
    },
    closeMoudle() {
      this.visible = false
    }
  }
}
</script>
<style scoped>
.login-container {
  text-align: center;
  padding: 30px;
  /* border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
.login-title {
  font-size: 26px;
  margin-bottom: 10px;
  color: #000000;
}
.qr-description-top {
  color: #666;
  margin-bottom: 10px;
}
.qr-description-bottom {
  color: #666;
  margin-top: 20px;
}
</style>
