<template>
  <div>
    <PageHeader />
    <router-view />
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'

export default {
  name: 'Main',
  components: {
    PageHeader,
    PageFooter
  }
}
</script>

<style scoped>
.logo-content {
  background-color: pink;
  height: 200px;
  width: 100%;
}

.logo-content .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
</style>
