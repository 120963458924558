<template>
  <div class="page-footer">
    <div class="footer-page-depth">
      <dl class="footer-list">
        <dt>友情链接</dt>
        <dd><a href="http://www.jinshan.gov.cn/" target="_blank">上海市金山区人民政府</a></dd>
        <dd><a href="https://www.jssyjt.net/" target="_blank">上海金山商业发展（集团）有限公司</a></dd>
        <dd><a href="https://www.jssyjt.net/" target="_blank">上海市金山区供销合作社</a></dd>
      </dl>
      <dl class="footer-list">
        <dt>房屋租赁招商服务</dt>
        <dd><a href="http://www.ezhizf.com/" target="_blank">e指找房</a></dd>
        <dd> <span>联系我们：021-57316613</span></dd>
        <dd> <span>联系地址：上海市金山区朱泾镇金龙新街488号8F</span></dd>
      </dl>

      <dl class="footer-list">
        <dt>关注我们</dt>
        <dd><img src="@/assets/wxQrcode.png" /> </dd>
      </dl>
    </div>

    <div class="footer-copyright">
      <div>©2020-2024 ezhizf.com 上海金山商业发展（集团）有限公司 上海市金山区供销合作社 版权所有</div>
      <div><a href="https://beian.miit.gov.cn/" target="_blank">ICP备案：沪ICP备19034929号-1</a></div>
      <div><a href="http://www.beian.gov.cn/" target="_blank">沪公网安备：31011602002273号</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>

<style scoped>
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fefefe;
}

.page-footer {
  width: 100%;
  background-color: #0060c9;
  color: #fefefe;
  padding: 60px 0 20px 0;
}

.footer-page-depth {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.footer-list {
  text-align: left;
  font-size: 16px;
  margin: 0 20px;
}

.footer-list dt {
  margin-bottom: 20px;
  font-weight: 600;
}

.footer-list dd {
  border: 1px solid transparent;
  color: #fff;
}

.footer-list dd a:hover {
  border-bottom: 1px solid #fefefe;
}

.footer-copyright {
  width: 1200px;
  margin: auto;
  padding: 10px 20px;
  border-top: 1px solid #fff;
  display: flex;
  justify-content: space-between;
}
</style>
